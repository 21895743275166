<script setup lang="ts">
import UProductPageBlock from "~/components/Marketing/UProductPageBlock.vue"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
const data = useProductVariantsStore()
const productBlocks = ref<ContentProductPageBlocks[] | undefined>()

watchEffect(() => {
  if (data.product && data.marketingData) {
    productBlocks.value =
      data.marketingData[data.product]?.productPageBlocks
  }
})
const heroSectionMounted = ref(false)
const style = getComputedStyle(document.body)
style.getPropertyValue("--theme-loaded")
</script>

<template>
  <div>
    <div
      class="flex flex-col items-center justify-center gap-6 px-8 text-xl text-[--theme-darkest] md:px-24 lg:gap-20"
    >
      <UProductPageHero @mounted="heroSectionMounted = true" />
      <UProductPagePensum v-if="isFeatureEnabled" />
      <template v-if="heroSectionMounted">
        <UProductPageBlock
          v-for="productBlock in productBlocks"
          :key="productBlock.contentId"
          img-text-color="white"
          :product-block-data="productBlock"
        />
      </template>
    </div>
    <UProductPageHero
      position="bottom"
      class="mt-24 py-24 md:px-24"
    />
  </div>
</template>

<route lang="yaml">
name: productPage
meta:
  layout: productPage
</route>
