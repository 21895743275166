<script lang="ts" setup>
import { Ref, ref, computed } from "vue"
import { useAudioStore } from "../../store/audio"
import { getMinutesAndSeconds } from "../../composables/getMinutesAndSeconds"

const audioStore = useAudioStore()
const personalizationStore = usePersonalizationStore()
const licenceStore = useLicenceStore()
const productContentStore = useProductContentStore()

const router = useRouter()

const audio: Ref<HTMLAudioElement | null> = ref(null)

const currentTrack = computed(() =>
  audioStore.playList.find(track => track.active),
)
const progress: Ref<number> = ref(0)

export type PlayPauseState = "pause" | "play"
const playPauseState: Ref<PlayPauseState> = ref("pause")

type PlayerSizeStatus = "expand-diagonal" | "minimize"
const playerSizeStatus: Ref<PlayerSizeStatus> = ref("expand-diagonal")

const duration: Ref<number> = ref(0)
const currentTime: Ref<number> = ref(0)

const isFullscreen: Ref<boolean> = ref(false)
const isMobile = computed(() => useWindowSize().width.value < 768)
const isMinimizedOutsideOfArticle = computed(
  () =>
    !useProductContentStore().activeArticle && !isFullscreen.value,
)
const hasPaybanner = computed(() => !useLicenceStore().activeLicence)

const hasActiveArticleAndPlayer = computed(() => {
  return audioStore.activePlayer && productContentStore.activeArticle
})

const hasAudio = computed(() => {
  return productContentStore?.activeArticle?.audio
})

const hasSwitchedArticle = computed(() => {
  return (
    currentTrack.value?.id !== productContentStore.activeArticle?.id
  )
})

const shouldSwitchTrack = computed(() => {
  return (
    hasActiveArticleAndPlayer.value &&
    !isFullscreen.value &&
    hasAudio.value &&
    hasSwitchedArticle.value
  )
})

const handlePlay = () => {
  if (audio.value) {
    if (audio.value.paused) {
      playPauseState.value = "pause"
      audio.value.play()
    } else {
      playPauseState.value = "play"
      audio.value.pause()
    }
  } else playPauseState.value = "pause"
}

const handleClosePlayer = () => {
  playPauseState.value = "pause"
  playerSizeStatus.value = "expand-diagonal"
  audioStore.togglePlayer()
}

const handlePlayerSize = () => {
  if (playerSizeStatus.value === "expand-diagonal") {
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        fromFullscreen: true,
      },
    })
    playerSizeStatus.value = "minimize"
  } else playerSizeStatus.value = "expand-diagonal"
  isFullscreen.value = !isFullscreen.value
}

const onSliderEvent = (t: number) => {
  if (audio.value) {
    audio.value.currentTime = t
    currentTime.value = audio.value.currentTime
  }
}

const onTimeUpdate = () => {
  if (
    audio.value &&
    audioStore.playListSubject &&
    audioStore.playListSubject.playTime
  ) {
    currentTime.value = audio.value.currentTime
    progress.value =
      (currentTime.value * 100) / audioStore.playListSubject.playTime
  }
}

const updateMeta = () => {
  duration.value = audio.value?.duration ?? 0
  currentTime.value = audio.value?.currentTime ?? 0

  if (!audioStore.activePlayer) {
    audioStore.togglePlayer()
  }
  playPauseState.value = "pause"
  audio.value?.play()
}

const handleSkip = (seconds: number) => {
  if (audio.value) {
    audio.value.currentTime += seconds
    currentTime.value = audio.value.currentTime
  }
}

const handleSpeed = (speed: number) => {
  if (audio.value) audio.value.playbackRate = speed
}

onMounted(() => {
  if (audio.value) {
    playPauseState.value = audio.value.paused ? "play" : "pause"
  }
})

// Pause track if it ends and the player is minimized, if in fullsreen; play the next track in the playlist.
watchEffect(() => {
  if (currentTime.value === duration.value && duration.value > 0) {
    if (!isFullscreen.value) {
      playPauseState.value = "play"
    } else {
      const currentTrackIndex = audioStore.playList.findIndex(
        i => i.active,
      )
      const nextTrackId =
        audioStore.playList[currentTrackIndex + 1].id
      audioStore.setCurrentTrack(nextTrackId)
    }
  }
})

// Add article to read history
watchEffect(() => {
  if (currentTime.value === duration.value && duration.value > 0) {
    const articleId = currentTrack.value?.id
    if (articleId && licenceStore.activeLicence) {
      personalizationStore.addArticleToReadHistory(articleId, true)
    }
  }
})

// Switch track if active article changes
watchEffect(() => {
  if (shouldSwitchTrack.value) {
    if (!router.currentRoute.value.query.fromFullscreen) {
      audioStore.setCurrentTrack(productContentStore.activeArticle.id)
    } else {
      audio.value?.pause()
      playPauseState.value = "play"
    }
  }
})
</script>
<template>
  <section
    v-if="
      audioStore.activePlayer &&
      useProductVariantsStore().activeVariant
    "
    id="fullscreen-element"
    class="fixed bottom-28 left-0 z-50 flex h-24 w-full items-center justify-center"
    :class="{
      '!bottom-0 !h-full ': isFullscreen,
      '!bottom-32': hasPaybanner && isMinimizedOutsideOfArticle,
      '!bottom-10': !hasPaybanner && isMinimizedOutsideOfArticle,
    }"
    @keyup.esc="handlePlayerSize"
    @keyup.exact.down="isFullscreen ? handlePlayerSize() : undefined"
    @keyup.exact.left="() => handleSkip(-15)"
    @keyup.exact.right="() => handleSkip(15)"
    @keyup.exact.space="handlePlay"
  >
    <div
      class="relative flex w-[90%] items-center justify-start gap-6 rounded-lg bg-darker px-10 py-4 shadow-lg md:gap-10"
      :class="{
        'h-full w-full flex-col justify-end rounded-none py-10':
          isFullscreen,
      }"
    >
      <UAudioPlayerPlaylist
        v-if="isFullscreen"
        :currently-playing="currentTrack?.id"
        :playlist="audioStore.playList"
      />
      <div class="flex-col items-center justify-center">
        <div
          class="flex w-full flex-col items-center justify-center gap-2 font-national2 text-platform-paper"
        >
          <h5
            v-if="isFullscreen"
            class="text-normal text-center text-xl text-lighter"
          >
            {{ productContentStore.activeSubject?.title }}
          </h5>
          <h4
            :role="isMobile ? 'button' : undefined"
            :aria-controls="
              isMobile ? 'fullscreen-element' : undefined
            "
            class="w-full text-center text-2xl font-extrabold leading-6"
            :class="{
              'max-w-32 truncate !pt-0 text-xl sm:max-w-48 md:max-w-md lg:max-w-[15rem] xl:max-w-lg':
                !isFullscreen,
            }"
            @click.self="
              !isFullscreen && isMobile ? handlePlayerSize() : null
            "
          >
            {{ currentTrack?.title }}
          </h4>
        </div>
        <h5 v-if="!isFullscreen" class="block text-lighter lg:hidden">
          -{{ getMinutesAndSeconds(duration - currentTime) }}
        </h5>
      </div>
      <div
        class="flex w-full items-center justify-between"
        :class="{
          ' max-w-[90%] flex-col pb-10 md:max-w-[70%]': isFullscreen,
          '!justify-end gap-4': !isFullscreen,
        }"
      >
        <UAudioPlayerControls
          :handle-play="handlePlay"
          :handle-skip="handleSkip"
          :play-pause-state="playPauseState"
          :is-fullscreen="isFullscreen"
        />
        <UAudioSlider
          :class="{ 'hidden lg:flex': !isFullscreen }"
          :current-time="currentTime"
          :handle-speed="handleSpeed"
          :max="duration"
          :is-fullscreen="isFullscreen"
          @current-time-event="t => onSliderEvent(t)"
        />
        <div class="flex items-center justify-center gap-4">
          <UButtonNew
            aria-controls="fullscreen-element"
            :aria-label="
              isFullscreen
                ? 'Skru av fullskjerm for lydavspilleren'
                : 'Skru på fullskjerm for lydavspilleren'
            "
            :aria-expanded="isFullscreen"
            variant="quite"
            theme="white"
            :class="{
              'absolute right-10 top-10': isFullscreen,
              'hidden lg:flex': !isFullscreen,
            }"
            class="hover:text-lighter"
            @click="handlePlayerSize()"
            ><UIcon :name="playerSizeStatus" size="lg"
          /></UButtonNew>
          <UButtonNew
            v-if="!isFullscreen"
            variant="quite"
            theme="white"
            class="hover:text-lighter"
            @click="handleClosePlayer()"
            ><UIcon name="close" size="lg"
          /></UButtonNew>
        </div>
      </div>
      <audio
        ref="audio"
        autoplay
        :src="currentTrack?.url"
        @timeupdate="onTimeUpdate"
        @loadedmetadata="updateMeta"
      />
    </div>
  </section>
</template>
