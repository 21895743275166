<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  currentTime: {
    type: Number as PropType<number>,
    default: 0,
  },
  max: {
    type: Number as PropType<number>,
    default: 0,
  },
  handleSpeed: {
    type: Function as PropType<(value: number) => void>,
  },
  isFullscreen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(["currentTimeEvent"])

const currentTimeLocal = ref(0)

watch(props, () => {
  currentTimeLocal.value = props.currentTime
})

const seek = (e: Event) => {
  const target = <HTMLInputElement>e.target
  currentTimeLocal.value = target.value as unknown as number
  emit("currentTimeEvent", currentTimeLocal.value)
}
</script>

<template>
  <div
    class="flex w-full items-center justify-center gap-4 2xl:mx-10"
    :class="{
      'flex-row-reverse ': isFullscreen,
    }"
  >
    <UAudioPlaybackSpeed
      @speed-event="speed => props.handleSpeed(speed)"
    />
    <div class="flex w-full items-center justify-center gap-2">
      <span class="w-16 text-white">{{
        getMinutesAndSeconds(currentTime)
      }}</span>
      <input
        v-model="currentTimeLocal"
        aria-label="Lydglider"
        type="range"
        :min="0"
        :max="max"
        step="0.01"
        class="w-full min-w-32 !max-w-[90%]"
        @input="seek"
      />
      <span class="w-16 text-white">{{
        getMinutesAndSeconds(max)
      }}</span>
    </div>
  </div>
</template>

<style scoped lang="postcss">
input {
  @apply h-[2px]
    w-full
    appearance-none
    bg-lightest
    opacity-80
    transition-opacity
    duration-200
    hover:opacity-100;
}

input::-webkit-slider-thumb {
  @apply h-[15px]
    w-[15px]
    appearance-none
    rounded-full
    bg-white;
}
</style>
