<script setup lang="ts">
import { useLoginPageUrl } from "~/composables/useLoginPageUrl"

const productContent = useProductContentStore()
const route = useRoute<"article">()
const router = useRouter()
const { isAuthorized } = useAuth()
const loginPageUrl = useLoginPageUrl()

const productParam = computed(() => route.params.product)

const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--color-platform-paper)"

const showArticleFooter = computed(
  () => route.params["article"] !== "forside",
)

function scroll() {
  const article = Array.from(
    document.querySelectorAll("[id^=article-page-]"),
  ) as HTMLElement[]

  if (article.length) {
    const [page] = article
    page.focus()
  }
}
const goBack = () => {
  if (canGoBack.value) {
    if (productContent.previousArticle?.frontendHref) {
      router.push(productContent.previousArticle?.frontendHref)
    } else {
      console.info(
        "Navigating to 404 page:'productContent.previousArticle?.frontendHref' is not defined",
        productContent.previousArticle?.frontendHref,
      )
      router.push({
        path: "/404",
        query: {
          r: "Vi fant ikke artikkelen du så etter",
        },
      })
    }
  }
}
const goForward = () => {
  if (productContent.nextArticle?.frontendHref) {
    router.push(productContent.nextArticle?.frontendHref)
  } else {
    console.info(
      "Navigating to 404 page:'productContent.nextArticle?.frontendHref' is not defined",
      productContent.nextArticle?.frontendHref,
    )
    router.push({
      path: "/404",
      query: {
        r: "Vi fant ikke artikkelen du så etter",
      },
    })
  }
}

const canGoBack = computed(() => {
  if (
    productContent.previousArticle?.id &&
    !productContent.previousArticle?.restricted
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div
    id="article-layout"
    class="article-layout !bg-platform-paper outline-none"
    style="view-transition-name: article"
    @keyup.esc="
      router.push(
        `/produkter/${productParam}/${route.params.subject}`,
      )
    "
    @keyup.exact.left="goBack"
    @keyup.exact.right="goForward"
    @keyup.up="scroll"
    @keyup.down="scroll"
  >
    <u-article-loading-screen
      v-if="productContent.activeArticle === null"
    />

    <div
      class="grid h-[100dvh] grid-rows-[0,minmax(0,1fr),max-content]"
    >
      <template v-if="productContent.activeArticle === undefined">
        <div class="richtext row-start-2 place-self-center">
          <h1>Fant ikke artikkelen</h1>
          <p v-if="!isAuthorized">
            Kanskje du ser den hvis du
            <router-link :to="loginPageUrl">logger inn</router-link>?
          </p>

          <router-link
            class="mt-6 block"
            :to="`/produkter/${route.params.product}/${route.params.variant}/${route.params.subject}`"
          >
            Ta meg til forsiden av kapittelet
          </router-link>
        </div>
      </template>
      <template v-else>
        <UArticleNavigationTop
          v-if="productContent.activeArticle"
          :article="productContent.activeArticle"
          :go-back="goBack"
          :can-go-back="canGoBack"
        />
        <router-view v-if="productContent.activeArticle" />
        <u-article-footer v-if="showArticleFooter" />
      </template>
    </div>
  </div>
</template>
