import { variantFullNameMap } from "~/utilities/constants"

const currencyFormater = Intl.NumberFormat("nb", {
  style: "currency",
  currency: "NOK",
  currencyDisplay: "narrowSymbol",
})

const dateFormater = Intl.DateTimeFormat("nb", {
  month: "long",
  day: "numeric",
})

export function formatCurrency(amount: number) {
  return currencyFormater
    .formatToParts(amount)
    .filter(({ type }) => type !== "currency")
    .map(({ type, value }) =>
      type === "fraction" && value === "00" ? "-" : value,
    )
    .join("")
}

export function formatDate(
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) {
  if (options !== null) {
    return Intl.DateTimeFormat("nb", options).format(date)
  }

  // If no options are provided, reuse the default formatter to prevent creating new formatters.
  return dateFormater.format(date)
}

export function formatYearRange(from: number, to?: number | null) {
  const { abs } = Math
  const beforeEra = "fvt."
  const afterEra = "evt."

  if (to === null || to === undefined) {
    return from < 0 ? `${abs(from)} ${beforeEra}` : `${from}`
  }

  if (to < 0 && from < 0) {
    return `${abs(from)}-${abs(to)} ${beforeEra}`
  }

  if (to < 0 && from >= 0) {
    return `${abs(from)} ${beforeEra} - ${to} ${afterEra}`
  }

  return `${from}-${to}`
}

export const sanitizeCurriculum = (text: string) => {
  return text
    .replaceAll("og", ",")
    .replaceAll("eller", ",")
    .replaceAll(" ", "")
}

export const formatList = (items: string[]): string => {
  const formatter = new Intl.ListFormat("no", {
    style: "long",
    type: "conjunction",
  })

  return formatter.format(items)
}

type ParsedResult = {
  shortNamesAndCourseCodes: [string, string][] // Array of tuples [Institution, CourseCode]
  vanitySentence: string // Optional vanity sentence
}

/**
 * Parses the provided `pensumFor` string to extract institution names and course codes.
 * If provided, the first item in the string array is considered the vanity sentence.
 * The function splits the remaining items based on " og " and returns an object containing
 * an array of tuples (institution and associated course code) and the vanity sentence.
 *
 * @param {string} pensumFor - A string containing institutions and course codes in the format "[vanitySentence] UiO(EXPHIL03) og UiA(EX-100)".
 * @param {string} variantShortName - A short name used to retrieve the full variant name if `pensumFor` is empty.
 *
 * @returns {ParsedResult} An object containing the vanity sentence and an array of tuples where each tuple contains an institution and an optional course code.
 *
 * @example
 * // Example with multiple institutions, course codes, and a vanity sentence
 * const result1 = parseInstitutionNameAndCode("[Bachelorstudier i økonomi] UiO(EXPHIL03) og UiA(EX-100)", "somevariant");
 * console.log(result1);
 * // {
 * //   shortNamesAndCourseCodes: [ ['UiO', 'EXPHIL03'], ['UiA', 'EX-100'] ],
 * //   vanitySentence: 'Bachelorstudier i økonomi'
 * // }
 *
 * @example
 * // Example with a single institution and no course code
 * const result2 = parseInstitutionNameAndCode("NTNU", "somevariant");
 * console.log(result2);
 * // {
 * //   shortNamesAndCourseCodes: [ ['NTNU', ''] ],
 * //   vanitySentence: ''
 * // }
 *
 * @example
 * // Example when `pensumFor` is empty, fallback to `variantShortName`
 * const result3 = parseInstitutionNameAndCode("", "somevariant");
 * console.log(result3);
 * // {
 * //   shortNamesAndCourseCodes: [ ['Some Variant Full Name', ''] ],
 * //   vanitySentence: ''
 * // }
 */
export const parseInstitutionNameAndCode = (
  pensumFor: string,
  variantShortName: string,
): ParsedResult => {
  let vanitySentence = ""
  let institutionData = pensumFor

  // Check if there's a vanity sentence wrapped in []
  const vanityMatch = pensumFor.match(/^\[(.*?)\]/)
  if (vanityMatch) {
    vanitySentence = vanityMatch[1].trim()
    institutionData = pensumFor.replace(vanityMatch[0], "").trim()
  }

  // If institutionData is empty, use variantShortName
  if (institutionData.length === 0) {
    const variantFullName =
      (variantFullNameMap[
        variantShortName.toLowerCase()
      ] as string) ?? variantShortName
    return {
      shortNamesAndCourseCodes: [[variantFullName, ""]],
      vanitySentence,
    }
  }

  // Parse the remaining part for institution names and course codes
  const shortNamesAndCourseCodes = institutionData
    .split(" og ")
    .map(parseCourseString)

  return {
    shortNamesAndCourseCodes,
    vanitySentence,
  }
}

/**
 * Parses a single course string and extracts the institution and course code (if available).
 *
 * @param {string} courseString - A string representing an institution and an optional course code, like "UiO(EXPHIL03)" or "NTNU".
 *
 * @returns {[string, string]} A tuple containing the institution and the optional course code.
 */
const parseCourseString = (
  courseString: string,
): [string, string] => {
  const [institution, courseCode] = courseString.includes("(")
    ? courseString.split(/[()]/).map(s => s.trim())
    : [courseString.trim(), ""]
  return [institution, courseCode]
}
