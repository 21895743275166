<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"

defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: true,
  },
  released: {
    type: Boolean,
    default: true,
  },
})

const router = useRouter()
</script>

<template>
  <div
    class="flex flex-col gap-7 rounded-2xl bg-platform-white font-national2 drop-shadow-xl md:gap-14 lg:flex-row"
  >
    <img
      v-if="product.variants[0].eCommerce?.imageUrl"
      :src="product.variants[0].eCommerce?.imageUrl"
      class="h-[281px] w-full cursor-pointer rounded-bl-none rounded-tl-2xl rounded-tr-2xl object-contain p-4 md:h-auto lg:w-[320px] lg:rounded-bl-2xl lg:rounded-tl-2xl lg:rounded-tr-none"
      :class="`bg-${product.theme}-lightest`"
      :alt="
        product.variants[0].eCommerce?.imageDescription ||
        `Produktillustrasjon for ${product.productTitle} `
      "
      @click="
        product.hasAtLeastOneReleasedProduct &&
          router.push(
            `/produkter/${product.productTitle
              .replaceAll(' ', '-')
              .toLowerCase()}`,
          )
      "
    />
    <div
      v-else
      class="h-[281px] w-full cursor-pointer rounded-bl-none rounded-tl-2xl rounded-tr-2xl p-4 md:h-[320px] lg:w-[320px] lg:min-w-[320px] lg:rounded-bl-2xl lg:rounded-tl-2xl lg:rounded-tr-none"
      :class="`bg-${product.theme}-lightest`"
    ></div>
    <div
      class="flex w-full flex-col gap-4 px-6 pb-6 md:pr-24 lg:pt-12"
    >
      <h4
        class="font-national2compressed text-[32px] font-extrabold uppercase leading-10 text-[--theme-dark] md:text-5xl"
      >
        Kunne
        <span v-html="product.productTitleHtml" />
        {{ !product.productTitleHtml ? product.productTitle : null }}
      </h4>
      <p
        v-if="product.groupSalesPitch?.length > 2"
        class="pr-10 text-lg md:text-xl"
        v-html="product.groupSalesPitch"
      />
      <UProductActions :product="product" />
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
