<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"

defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: true,
  },
})

const showVariantModal = inject("showVariantModal") as Ref<boolean>
const emit = defineEmits(["close"])
</script>
<template>
  <div>
    <u-modal v-model="showVariantModal" @close="emit('close')">
      <template #header>
        <h1
          class="bold font-national2condensed text-h-1-national-2-c text-dark-blue-80"
        >
          Hvor studerer du?
        </h1>
      </template>
      <div class="flex flex-col gap-2">
        <UButtonNew
          v-for="variant in product?.variants.filter(
            v => v.isReleased,
          )"
          :key="variant.shortVariantLabel"
          class="mb-2"
          :to="`/produkter/${product.productKey}/${variant.variantKey}`"
          size="md"
          theme="cta"
          >{{
            variant.curriculumFor.replaceAll(" og ", " eller ")
          }}</UButtonNew
        >
      </div>
      Finner du ikke ditt studiested?
      <a
        class="underline"
        href="https://om.kunne.universitetsforlaget.no/hjelpesider/kb-tickets/new"
        >Kontakt oss</a
      >
    </u-modal>
  </div>
</template>
