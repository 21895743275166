<script setup lang="ts">
import { debounce } from "lodash-es"
import type { PropType } from "vue"
import type { Article } from "~/types/article"
import { getColorClassNameFromScheme } from "~/utilities/colors"
import { useWindowSize } from "@vueuse/core"
import { toDisplayLanguage } from "../../utilities/videoUtils"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const baseUri = import.meta.env.VITE_IBEXA_BASEURL
const store = useProductContentStore()
const route = useRoute<"article">()

const rootEl = ref<HTMLDivElement | null>()
const scrollerEl = ref<HTMLDivElement | null>(null)
const currentSubject = computed(() =>
  store.subjects.find(i =>
    i.href.includes(String(route.params.subject)),
  ),
)

const slides = computed(() => currentSubject.value?.introPages ?? [])
const withImage = ref(!!props.data.img?.src?.length)

const backgroundVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.data.colorTag as string, ["bg"])

const getStyle = computed(() => {
  if (withImage.value) {
    return {
      backgroundImage: `linear-gradient(0deg, rgba(18, 11, 43, 0.5), rgba(18, 11, 43, 0.5)), url('${props.data.img?.src}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: props.data.img
        ? `${getImagePosition(props.data.img)}`
        : "",
      backgroundSize: "cover",
      viewTimeline: "--article-slide-index x",
    }
  }
})

const getImagePosition = (image: {
  focalPointY?: number
  focalPointX?: number
  width?: number
  height?: number
}) => {
  const { focalPointY, focalPointX, width, height } = image
  if (focalPointY && focalPointX && width && height) {
    return `${(focalPointX / width) * 100}% ${(focalPointY / height) * 100}%`
  } else {
    return "50% 50%"
  }
}

const { height, width } = useWindowSize()
const lastSlideButtonPosition = computed(() => {
  return ((height.value - 135) / height.value) * 100
})

function nextSlide() {
  scrollerEl.value?.scrollBy({ left: innerWidth, behavior: "smooth" })
}

function prevSlide() {
  scrollerEl.value?.scrollBy({
    left: innerWidth * -1,
    behavior: "smooth",
  })
}

function toSlide(n: number) {
  scrollerEl.value?.scrollTo({
    left: innerWidth * n,
    behavior: "smooth",
  })
}

function getSlideIndex() {
  if (scrollerEl.value == null) return 0
  const { scrollLeft, clientWidth } = scrollerEl.value
  return Math.round(scrollLeft / clientWidth)
}

const animationTimelineSupported = ref(
  CSS.supports("animation-timeline", "--article-slide-index"),
)
// Fallback for when scroll animations are not supported
const activeSlideIndexFallbackValue = ref(0)
const activeSlideFallbackTracker = debounce(
  () => {
    activeSlideIndexFallbackValue.value = getSlideIndex()
  },
  200,
  { leading: true },
)

onMounted(() => {
  rootEl.value?.focus()

  // Fallback for when scroll animations are not supported
  scrollerEl.value?.addEventListener(
    "onscrollend" in scrollerEl.value ? "scrollend" : "scroll",
    activeSlideFallbackTracker,
  )
})

onUnmounted(() => {
  // Fallback for when scroll animations are not supported
  scrollerEl.value?.removeEventListener(
    "onscrollend" in scrollerEl.value ? "scrollend" : "scroll",
    activeSlideFallbackTracker,
  )
})

// These two render functions are used to support navigation in both Safari and other browsers
const renderBackButton = computed(() => {
  if (animationTimelineSupported.value) {
    return true
  } else {
    return activeSlideIndexFallbackValue.value > 0
  }
})

const renderNextButton = computed(() => {
  if (animationTimelineSupported.value) {
    return true
  } else {
    return activeSlideIndexFallbackValue.value < slides.value.length
  }
})
</script>

<template>
  <div
    ref="rootEl"
    class="timeline-scope grid outline-none"
    :style="{
      'grid-template-rows': '1fr',
      '--timeline-scope': `--article-slide-index, ${slides
        .map((_, index) => `--article-slide-${index}`)
        .join(', ')}`,
    }"
    tabIndex="-1"
    @keyup.right="
      event => {
        if (getSlideIndex() < slides.length) {
          event.stopPropagation()
          nextSlide()
        }
      }
    "
    @keyup.left="
      event => {
        if (getSlideIndex() > 0) {
          event.stopPropagation()
          prevSlide()
        }
      }
    "
  >
    <template v-if="slides.length > 0">
      <div class="u-grid fixed left-0 right-0 top-2 z-10">
        <div
          class="col-start-2 col-end-18 flex h-[85px] items-end gap-1 md:col-start-4 md:col-end-16 md:gap-6"
        >
          <button
            class="h-2 w-60 cursor-pointer rounded-full"
            :class="{
              'bg-black': activeSlideIndexFallbackValue !== 0,
              'opacity-30': activeSlideIndexFallbackValue !== 0,
            }"
            :style="{
              ...(activeSlideIndexFallbackValue === 0 && {
                'backdrop-filter':
                  'grayscale(1) contrast(1000) invert()',
              }),
              animation: 'linear opacity 1s',
              'animation-fill-mode': 'both',
              'animation-timeline': `--article-slide-index`,
            }"
            @click="toSlide(0)"
          />
          <button
            v-for="(_slide, index) in slides"
            :key="index"
            class="h-2 w-60 cursor-pointer rounded-full"
            :class="{
              'bg-black': activeSlideIndexFallbackValue !== index + 1,
              'opacity-30':
                activeSlideIndexFallbackValue !== index + 1,
            }"
            :style="{
              ...(activeSlideIndexFallbackValue === index + 1 && {
                'backdrop-filter':
                  'grayscale(1) contrast(1000) invert()',
              }),
              animation: 'linear opacity 1s',
              'animation-fill-mode': 'both',
              'animation-timeline': `--article-slide-${index}`,
            }"
            @click="toSlide(index + 1)"
          />
        </div>
      </div>

      <UButtonNew
        v-if="renderBackButton"
        circle
        variant="filled"
        theme="white"
        class="fixed left-6 top-[95%] z-10 -translate-y-1/2 transform md:top-[50%]"
        :style="{
          animation: 'linear hidden 1s',
          'animation-fill-mode': 'both',
          'animation-timeline': `--article-slide-index`,
          top:
            activeSlideIndexFallbackValue === slides.length &&
            width < 744
              ? `${lastSlideButtonPosition}%`
              : '',
        }"
        @click="prevSlide()"
      >
        <template #prepend>
          <u-icon name="arrow-left" />
        </template>
      </UButtonNew>

      <UButtonNew
        v-if="renderNextButton"
        circle
        variant="filled"
        theme="white"
        class="fixed right-6 top-[95%] z-10 -translate-y-1/2 transform md:top-[50%]"
        :style="{
          animation: 'linear hidden 1s',
          'animation-fill-mode': 'both',
          'animation-timeline': `--article-slide-${
            slides.length - 1
          }`,
        }"
        @click="nextSlide()"
      >
        <template #prepend>
          <u-icon name="arrow-right" />
        </template>
      </UButtonNew>
    </template>

    <div
      :ref="el => (scrollerEl = el as HTMLDivElement)"
      class="no-scrollbar grid w-full snap-x snap-mandatory auto-cols-[100%] grid-flow-col !grid-rows-1 overflow-auto scroll-smooth"
    >
      <article
        :style="getStyle"
        class="u-grid snap-center snap-always place-content-center !gap-y-2 py-16 md:place-items-center <md:!bg-none"
        :class="[
          {
            'md:auto-rows-auto':
              data.subjectPageTitlePosition === 'bottom' ||
              data.subjectPageTitlePosition === 'middle',
          },
          backgroundVal,
        ]"
      >
        <div
          v-if="!withImage"
          class="col-start-1 col-end-19 h-[66cqh] w-full md:hidden"
          :class="backgroundVal"
        />
        <img
          v-if="withImage"
          :src="data.img?.src"
          class="col-start-1 col-end-19 row-start-5 h-[55cqh] w-full object-cover md:hidden"
          :style="
            data.img?.focalPointX && data.img?.focalPointY
              ? `object-position: ${getImagePosition(data.img)}`
              : ''
          "
        />
        <h1
          class="order-1 col-start-2 col-end-16 row-start-6 font-national2condensed text-5xl font-medium uppercase leading-[48px] text-[--theme-darker] @md:order-none md:col-start-3 md:col-end-18 md:text-6xl md:text-white <md:pt-[var(--grid-col-width)/2]"
        >
          Intro: {{ data.title }}
        </h1>
      </article>
      <article
        v-for="(page, index) in slides"
        :key="index"
        class="u-grid snap-center snap-always md:mt-0 md:place-content-center md:place-items-center md:pt-20"
        :style="`view-timeline: --article-slide-${index} x`"
        :class="{
          [`${getColorClassNameFromScheme(
            page.colorTheme,
            ['bg', 'text'],
            {
              bwText: true,
            },
          )} place-content-center`]:
            page.colorTheme && page.template === 'Title',
          ['bg-platform-paper pt-28 text-black']:
            page.template !== 'Title',
          ['slide-text-container']:
            page.template === 'Text' || page.template === 'Video',
          ['slide-container']:
            page.template === 'Image with text' ||
            page.template === 'Text with image',
        }"
      >
        <template v-if="page.template === 'Image with text'">
          <div
            class="col-start-2 col-end-18 h-full md:col-start-4 md:col-end-9 md:h-auto"
          >
            <img
              v-if="page.image"
              :src="page.image.variation.uri"
              :alt="page.image.alternativeText"
              class="max-h-[100%] w-full object-cover"
              :style="
                page.image?.additionalData?.focalPointX &&
                page.image?.additionalData?.focalPointY
                  ? `object-position: ${getImagePosition(page.image)}`
                  : ''
              "
            />
          </div>

          <div
            class="richtext col-start-2 col-end-18 max-h-full overflow-y-auto md:col-start-10 md:col-end-16 md:flex md:flex-col"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else-if="page.template === 'Text with image'">
          <div
            class="col-start-2 col-end-18 h-full md:order-2 md:col-start-11 md:col-end-16 md:h-auto"
          >
            <img
              v-if="page.image"
              :src="page.image.variation.uri"
              :alt="page.image.alternativeText"
              class="max-h-[100%] w-full object-cover"
              :style="
                page.image?.additionalData?.focalPointX &&
                page.image?.additionalData?.focalPointY
                  ? `object-position: ${getImagePosition(page.image)}`
                  : ''
              "
            />
          </div>
          <div
            class="richtext col-start-2 col-end-18 max-h-full overflow-y-auto md:order-1 md:col-start-4 md:col-end-10 md:flex md:flex-col"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else-if="page.template === 'Title'">
          <div
            class="richtext col-start-2 col-end-18 h-fit text-3xl md:col-start-5 md:col-end-15 md:text-6xl"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else>
          <div
            class="richtext order-2 col-start-2 col-end-18 max-h-full overflow-y-auto md:order-1 md:col-start-5 md:col-end-15"
            v-html="page.richtext.html5"
          />

          <div
            v-if="page.image"
            class="order-1 col-start-2 col-end-18 overflow-hidden md:order-2 md:col-start-5 md:col-end-15 md:h-full"
            :style="
              page.image?.additionalData?.focalPointX &&
              page.image?.additionalData?.focalPointY
                ? `object-position: ${getImagePosition(page.image)}`
                : ''
            "
          >
            <img
              :src="page.image.variation.uri"
              :alt="page.image.alternativeText"
              class="max-h-[60vh]"
            />
          </div>

          <video
            v-else-if="page.video"
            class="order-1 col-start-1 col-end-20 md:order-2 md:col-start-4 md:col-end-16"
            autoplay
            muted
            controls
            controlslist="nodownload"
            preload="auto"
            :captions="page.video.captions"
            :poster="page.video.img.src || page.video.metadata.image"
            :src="page.video.metadata.elementURI"
          >
            <track
              v-for="caption in page.video.captions"
              :key="caption.captionFile.uri"
              :src="baseUri + caption.captionFile.uri"
              :srclang="caption.language"
              :label="toDisplayLanguage(caption.language)"
            />
          </video>
        </template>
      </article>
    </div>
    <div
      class="transition duration-200 ease-out"
      :class="
        activeSlideIndexFallbackValue === slides.length
          ? 'h-auto opacity-100'
          : 'h-0 opacity-0'
      "
    >
      <UArticleFooter />
    </div>
  </div>
</template>

<style>
.timeline-scope {
  timeline-scope: var(--timeline-scope);
}

@keyframes hidden {
  entry 0% {
    opacity: 1;
  }
  entry 10% {
    opacity: 0;
  }
  exit 90% {
    opacity: 0;
  }
  exit 100% {
    opacity: 1;
  }
}

@keyframes opacity {
  entry 0% {
    opacity: 0.3;
  }
  entry 100% {
    opacity: 1;
  }
  exit 0% {
    opacity: 1;
  }
  exit 100% {
    opacity: 0.3;
  }
}

.slide-container {
  @media (max-width: 744px) {
    grid-template-rows: minmax(150px, 30dvh) auto;
  }

  grid-template-rows: minmax(150px, 50dvh) auto;
}

.slide-text-container {
  grid-template-rows: repeat(2, minmax(100px, max-content));
  margin-top: 4rem;

  @media (max-width: 744px) {
    grid-template-rows: minmax(150px, max-content) auto;
    grid-gap: 1rem;
  }
}
</style>
