import { Variant } from "~/models/Content/ContentProducts"

export const getProductVariantPrice = (product?: Variant) => {
  if (product) {
    const price = product?.variants[0]?.eCommerce?.price
    return typeof price === "number" && price >= 0 ? price : ""
  }
  return ""
}

export const getProductVariantPriceLabel = (product?: Variant) => {
  const price = getProductVariantPrice(product)
  if (price !== undefined && price !== "") {
    if (typeof price === "number" && price > 0) {
      return " Tilbud "
    }
    return " Gratis "
  }
  return ""
}
