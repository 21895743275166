import gql from "graphql-tag"

export default gql`
  query ($locationId: [Int]) {
    content {
      mathTasks(query: { ParentLocationId: $locationId }) {
        edges {
          node {
            _type {
              identifier
            }
            assignmentText {
              html5
            }
            _location {
              priority
              id
              children {
                edges {
                  node {
                    content {
                      ... on SolutionContent {
                        id
                        _url
                        solutionText {
                          html5
                        }
                        _type {
                          identifier
                        }
                      }
                      ... on SuggestedSolutionContent {
                        id
                        title
                        _type {
                          identifier
                        }
                        _location {
                          children {
                            edges {
                              node {
                                priority
                                content {
                                  ... on SuggestedSolutionSectionContent {
                                    id
                                    sectionBody {
                                      html5
                                    }
                                    sectionImage {
                                      id
                                      alternativeText
                                      variation(
                                        identifier: fullwidth
                                      ) {
                                        uri
                                      }
                                    }
                                    _type {
                                      identifier
                                    }
                                    _info {
                                      relations {
                                        destinationContent {
                                          _url
                                          _type {
                                            identifier
                                          }
                                          ... on SourceContent {
                                            id
                                            type
                                            author
                                            title
                                            edition
                                            publisher
                                            publishYear
                                            publishDate {
                                              timestamp
                                            }
                                            url
                                          }
                                          ... on TermContent {
                                            id
                                            title
                                            definition {
                                              html5
                                            }
                                            explanation {
                                              html5
                                            }
                                            isFlashcard
                                          }
                                          ... on PersonContent {
                                            id
                                            name
                                            birthYear
                                            deceasedYear
                                            about {
                                              html5
                                            }
                                            image {
                                              variation(
                                                identifier: fullwidth
                                              ) {
                                                uri
                                              }
                                              height
                                              width
                                              alternativeText
                                            }
                                            imageCaption
                                          }
                                          ... on WhatIsContent {
                                            id
                                            title
                                            image {
                                              uri
                                              alternativeText
                                              variation(
                                                identifier: fullwidth
                                              ) {
                                                uri
                                              }
                                            }
                                            explanation {
                                              html5
                                            }
                                          }
                                          ... on SidenoteContent {
                                            id
                                            title
                                            type
                                            image {
                                              uri
                                              height
                                              width
                                              alternativeText
                                            }
                                            richtext {
                                              html5
                                            }
                                            video {
                                              id
                                              title
                                              image {
                                                alternativeText
                                                variation(
                                                  identifier: fullwidth
                                                ) {
                                                  uri
                                                }
                                              }
                                              previewTitle
                                              publishedBy
                                              published
                                              timestamps {
                                                description
                                                time
                                              }
                                              metadata
                                              embedCode
                                              transcription {
                                                plaintext
                                                html5
                                              }
                                              captions {
                                                captionFile {
                                                  uri
                                                  fileName
                                                }
                                                language
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        _type {
                          identifier
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
