<script setup lang="ts">
import { Relation } from "~/types/article"
import { FasitSidenote } from "~/models/FasitSidenote"

export type SidenoteData = Relation | FasitSidenote

defineProps({
  activeNote: {
    type: Object as PropType<SidenoteData | undefined>,
    default: undefined,
  },
  data: {
    type: Array as PropType<SidenoteData[]>,
    default: () => [],
  },
})

const route = useRoute<"article">()

watch(
  () => route.hash,
  () =>
    nextTick(() => {
      if (route.hash) {
        document.getElementById("sidenote-close-button")?.focus()
      }
    }),
  { immediate: true },
)

const ANIMATION_CLASS_ACTIVE =
  "transform translate-x-[0%] transition-transform duration-300"

const ANIMATION_CLASS_TO_FROM = "translate-x-[100%]"
</script>

<template>
  <transition
    name="sidenote"
    mode="out-in"
    :enter-active-class="`${ANIMATION_CLASS_ACTIVE} ease-out`"
    :leave-active-class="`${ANIMATION_CLASS_ACTIVE} ease-in`"
    :enter-from-class="ANIMATION_CLASS_TO_FROM"
    :leave-to-class="ANIMATION_CLASS_TO_FROM"
  >
    <div
      v-if="activeNote"
      id="activeNote"
      style="box-shadow: 0 0 8px rgba(0, 0, 0, 0.25)"
      class="sticky z-30 col-start-1 row-start-1 grid h-full grid-rows-[0,1fr] items-center place-self-end overflow-auto bg-grey-20 !font-national2 md:top-0 md:w-[var(--sidebar-width)] <md:fixed <md:inset-0 <md:overflow-auto <md:rounded"
      :class="{
        '!bg-yellow-20': activeNote.identifier === 'explainer',
        '!bg-[--theme-lightest]': activeNote.identifier === 'what_is',
        '!bg-[--color-green-lightest]':
          activeNote.identifier === 'solution',
      }"
    >
      <UCloseSidenoteButton />
      <USidenotePerson
        v-if="activeNote.identifier === 'person'"
        :note="activeNote"
      />
      <USidenoteWhatIs
        v-else-if="activeNote.identifier === 'what_is'"
        :note="activeNote"
      />
      <USidenoteTerm
        v-else-if="activeNote.identifier === 'term'"
        :note="activeNote"
      />
      <USidenoteSource
        v-else-if="activeNote.identifier === 'source'"
        :note="activeNote"
      />
      <USidenoteSolution
        v-else-if="activeNote.identifier === 'solution'"
        :note="activeNote"
      />
      <USidenoteDefault v-else :note="activeNote" />
    </div>
  </transition>
</template>
