import gql from "graphql-tag"

export default gql`
  query ($locationId: [Int]) {
    content {
      folders(query: { ParentLocationId: $locationId }) {
        edges {
          node {
            shortTitle
            title
            titleHtml
            colorTag
            image {
              alternativeText
              variation(identifier: reference) {
                uri
              }
            }
            _location {
              id
              priority
              parentLocationId
              hidden
              content {
                _type {
                  identifier
                }
              }
              children(sortBy: _priority, first: 50) {
                edges {
                  node {
                    id
                    hidden
                    priority
                    parentLocationId
                    content {
                      ... on SubjectContent {
                        id
                        title
                        shortTitle
                        _url
                        subjectPageTitlePosition
                        colorTag
                        _type {
                          identifier
                        }
                        introPages {
                          template
                          colorTheme
                          richtext {
                            html5
                          }
                          image {
                            alternativeText
                            fileName
                            variation(identifier: fullwidth) {
                              uri
                            }
                            width
                            height
                            additionalData {
                              focalPointX
                              focalPointY
                            }
                          }
                        }
                        image {
                          alternativeText
                          variation(identifier: fullwidth) {
                            uri
                          }
                          width
                          height
                          additionalData {
                            focalPointX
                            focalPointY
                          }
                        }
                        _info {
                          currentVersion {
                            versionNumber
                            creationDate {
                              timestamp
                            }
                            modificationDate {
                              timestamp
                            }
                          }
                          locations {
                            children {
                              totalCount
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
