import type {
  FolderEdgeNodes,
  FolderNode,
  FolderNodeContent,
  SubjectEdgeNodes,
  SubjectNode,
  SubjectNodeContent,
  SubjectNodeContentData,
} from "~/types/ibexa.subject"
import type { Folder } from "~/types/folder"
import type { Subject } from "~/types/subject"

import GraphqlSubjectsQuery from "~/graphql/documents/subjects-query"
import GraphqlFoldersQuery from "~/graphql/documents/folders-query"
import { graphqlClient } from "~/graphql/client"
import { FOLDER_REGEX } from "~/utilities/constants"
import { formatVideoData } from "../article/create-video"

interface SubjectResponse {
  subjects: Subject[]
  folders: Folder[]
}

export default async (
  locationId: number | undefined,
): Promise<SubjectResponse> => {
  if (!locationId) {
    throw new Error("No locationId provided")
  }

  const { data: querySubjects } = (await graphqlClient({
    query: GraphqlSubjectsQuery,
    variables: {
      locationId,
    },
  })) as SubjectEdgeNodes

  const subjectsOnlyNodes = querySubjects.content.subjects.edges.map(
    ({ node }: SubjectNode) => formatSubjectData(node._location),
  )

  const { data: queryFolders } = (await graphqlClient({
    query: GraphqlFoldersQuery,
    variables: {
      locationId,
    },
  })) as FolderEdgeNodes

  const folderQuery = queryFolders.content.folders.edges
  const folderEdges = folderQuery.map(({ node }: FolderNode) => node)
  const folders = folderEdges
    .map(node => formatFolderData(node))
    .sort((a, b) => a.priority - b.priority)

  let nodes = getFolderSubjects(folderEdges)
  nodes = [...nodes, ...subjectsOnlyNodes]

  return {
    subjects: nodes,
    folders,
  }
}

function formatFolderData(node: FolderNodeContent): Folder {
  const { title, titleHtml, shortTitle, colorTag, image, _location } =
    node
  const { id, hidden, parentLocationId, priority, content } =
    _location
  const {
    _type: { identifier },
  } = content

  return {
    id,
    title,
    titleHtml,
    shortTitle,
    colorTag,
    img: {
      alt: image ? image.alternativeText : "",
      src: image && image.variation ? image.variation.uri : "",
    },
    hidden,
    parentLocationId,
    priority,
    type: identifier,
    mediatypes: createMediaTypes(),
  }
}

function getFolderSubjects(
  arr: FolderNodeContent[] | SubjectNode[],
): Subject[] {
  return arr.flatMap((item: any) => {
    if (Array.isArray(item._location?.children.edges)) {
      return getFolderSubjects(item._location?.children.edges)
    } else if (typeof item === "object") {
      return formatSubjectData(item.node)
    } else {
      return []
    }
  })
}

function formatSubjectData(node: SubjectNodeContent): Subject {
  const { id, hidden, priority, parentLocationId, content } = node

  const {
    title,
    shortTitle,
    subjectPageTitlePosition,
    introPages,
    colorTag,
    image,
    _url,
    _info: { locations, currentVersion },
    _type,
  } = content as SubjectNodeContentData

  const { versionNumber, creationDate, modificationDate } =
    currentVersion
  const [
    {
      children: { totalCount: totalChildren },
    },
  ] = locations
  const created = creationDate.timestamp
  const modified = modificationDate.timestamp

  return {
    id,
    parentLocationId,
    priority,
    hidden,
    title,
    shortTitle,
    subjectPageTitlePosition,
    introPages: introPages?.map(page => ({
      ...page,
      image: page.image && {
        ...page.image,
        focalPointX: page.image?.additionalData?.focalPointX,
        focalPointY: page.image?.additionalData?.focalPointY,
        width: page.image?.width,
        height: page.image?.height,
      },
      video: page.video ? formatVideoData(page.video) : null,
    })),
    colorTag,
    rawHref: _url.replace(new RegExp(/[a-zA-Z]+\//i), ""),
    href: _url
      .replace(new RegExp(/[a-zA-Z]+\//i), "")
      .replace(FOLDER_REGEX, ""),
    img: {
      alt: image ? image.alternativeText : "",
      src: image ? image.variation?.uri : "",
      focalPointX: image?.additionalData?.focalPointX,
      focalPointY: image?.additionalData?.focalPointY,
      width: image?.width,
      height: image?.height,
    },
    mediatypes: createMediaTypes(),
    totalChildren,
    type: _type.identifier,
    created,
    modified,
    versionNumber,
  }
}

function createMediaTypes() {
  return {
    articles: {
      count: 0,
    },
    videos: {
      count: 0,
    },
    quizes: {
      count: 0,
    },
    podcasts: {
      count: 0,
    },
    hydrated: false,
  }
}
