<script setup lang="ts">
const productContent = useProductContentStore()
const { lastArticleInChapter } = useChapterProgress()
</script>

<template>
  <footer
    v-if="!productContent.hydrationInProgress"
    class="z-20 w-full bg-[--theme-darkest] py-6 text-white md:z-30 md:px-28"
  >
    <u-article-footer-last v-if="lastArticleInChapter" />
    <u-article-footer-next v-else />

    <UMarketingBanner v-if="productContent.nextArticle?.restricted" />
  </footer>
</template>
