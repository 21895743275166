import type {
  MathTaskSolutionsContent,
  SolutionContent,
  SuggestedSolutionContent,
} from "~/types/mathTaskSolutions"
import GraphqlMathTaskSolutionsDocument from "../../graphql/documents/mathTaskSolutions"

import { graphqlClient } from "~/graphql/client"
import {
  createHtmlFormatter,
  sanitizeMathMl,
} from "~/utilities/dom-parsing"
import { formatDestinationContentToRelation } from "~/hooks/article/format-data"

export interface FormattedMathTaskSolutions {
  mathTaskId: number
  assignmentText: string
  priority: number
  suggestedSolution: {
    title?: string
    sections?: FormattedSuggestedSolutionSection[]
  }
  solution?: SolutionContent
}

export interface FormattedSuggestedSolutionSection {
  id?: string
  priority: number
  sectionBody?: string
  sectionImage?: {
    id?: string
    variation?: {
      uri: string
    }
    alternativeText?: string
  }
}

export interface AssignmentSolutions {
  hasSuggestedSolutions: boolean
  assignments: Map<number, FormattedMathTaskSolutions>
}

export default async (
  locationId: number,
): Promise<AssignmentSolutions | undefined> => {
  const { data } = (await graphqlClient({
    query: GraphqlMathTaskSolutionsDocument,
    variables: {
      locationId,
    },
  })) as { data: MathTaskSolutionsContent }

  if (!data) return undefined

  const tasks = data.content.mathTasks.edges.sort(
    (a, b) => a.node._location.priority - b.node._location.priority,
  )
  if (!tasks.length) return undefined

  const parser = new DOMParser()
  const assignments = new Map<number, FormattedMathTaskSolutions>()
  let hasSuggestedSolutions = false

  for (const task of tasks) {
    const { assignmentText, _location } = task.node

    const mathTaskChildren = _location.children.edges
    const suggestedSolutionsRaw: SuggestedSolutionContent[] = []
    let solutionRaw: SolutionContent | undefined = undefined

    for (const edge of mathTaskChildren) {
      if (edge.node.content?._type?.identifier === "solution") {
        const solutionContent = edge.node.content as SolutionContent
        const solutionHtml = solutionContent.solutionText.html5

        solutionRaw = {
          ...solutionContent,
          solutionText: {
            html5: sanitizeMathMl(solutionHtml, parser).body
              .innerHTML,
          },
        }
      } else if (
        edge.node.content?._type?.identifier === "suggested_solution"
      ) {
        const data = edge.node.content as SuggestedSolutionContent
        suggestedSolutionsRaw.push(data)
      }
    }

    // A math task should only have one element of type SuggestedSolutionContent
    const suggestedSolutionSections = suggestedSolutionsRaw.length
      ? suggestedSolutionsRaw[0]._location?.children.edges
      : undefined

    if (!hasSuggestedSolutions) {
      if (
        suggestedSolutionSections &&
        suggestedSolutionSections.length > 0
      ) {
        hasSuggestedSolutions = true
      }
    }

    assignments.set(_location.id, {
      mathTaskId: _location.id,
      assignmentText: assignmentText.html5,
      priority: _location.priority,
      suggestedSolution: {
        title: "Løsningsforslag",
        sections: suggestedSolutionSections
          ?.map(section => {
            const relations = formatDestinationContentToRelation(
              section.node.content?._info?.relations?.map(
                it => it.destinationContent,
              ) ?? [],
            )
            const formatHtml = createHtmlFormatter(relations, parser)
            return {
              priority: section.node.priority,
              id: section.node.content?.id,
              sectionBody: formatHtml(
                section.node.content?.sectionBody.html5,
              ),
              sectionImage: section.node.content?.sectionImage,
            }
          })
          .sort((a, b) => a.priority - b.priority),
      },
      solution: solutionRaw,
    })
  }

  return { hasSuggestedSolutions, assignments }
}
