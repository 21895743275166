<script setup lang="ts">
const router = useRouter()
const route = useRoute<"article">()

function closeNote() {
  router.replace(route.fullPath.split("#")[0])
}
</script>

<template>
  <UButtonNew
    id="sidenote-close-button"
    class="sticky m-6 place-self-start justify-self-end bg-inherit"
    circle
    theme="white"
    variant="filled"
    aria-label="Lukk sidenotat"
    @click="closeNote()"
  >
    <template #prepend>
      <u-icon name="close" size="xxs" class="m-1" />
    </template>
  </UButtonNew>
</template>

<style scoped lang="postcss"></style>
