import { gql } from "graphql-tag"

export default gql`
  query ($locationId: Int) {
    _repository {
      location(locationId: $locationId) {
        id
        priority
        hidden
        parentLocationId
        children(sortBy: _priority, first: 50) {
          edges {
            node {
              id
              hidden
              priority
              parentLocationId
              content {
                ... on ArticleContent {
                  title
                  titleHtml
                  shortTitle
                  _url
                  _type {
                    identifier
                  }
                  template
                  bg
                  color
                  intro {
                    plaintext
                    html5
                  }
                  body {
                    plaintext
                    html5
                    html5_edit
                    text
                    xml
                  }
                  image {
                    id
                    height
                    width
                    variation(identifier: fullwidth) {
                      uri
                    }
                    alternativeText
                    text
                    additionalData {
                      focalPointX
                      focalPointY
                    }
                  }
                  hasPictureFrame
                  pictureColor
                  pictureText {
                    html5
                  }
                  imageCaption {
                    html5
                  }
                  imageSize
                  relatedArticles {
                    ... on VideoContent {
                      id
                      title
                      image {
                        alternativeText
                        variation(identifier: fullwidth) {
                          uri
                        }
                      }
                      previewTitle
                      publishedBy
                      published
                      timestamps {
                        description
                        time
                      }
                      metadata
                      embedCode
                      transcription {
                        plaintext
                        html5
                      }
                      captions {
                        captionFile {
                          uri
                          fileName
                        }
                        language
                      }
                    }
                    ... on QuizContent {
                      id
                      title
                      quizId
                    }
                    ... on AudioContent {
                      id
                      title
                      shortTitle
                      caption
                      timestamps {
                        description
                        time
                      }
                      metadata
                    }
                    _type {
                      identifier
                    }
                  }
                  _type {
                    identifier
                  }
                  image {
                    alternativeText
                    variation(identifier: fullwidth) {
                      uri
                    }
                  }
                  _info {
                    currentVersion {
                      versionNumber
                    }
                    relations {
                      destinationContent {
                        _url
                        _type {
                          identifier
                        }
                        ... on SourceContent {
                          id
                          type
                          author
                          title
                          edition
                          publisher
                          publishYear
                          publishDate {
                            timestamp
                          }
                          url
                        }
                        ... on TermContent {
                          id
                          title
                          definition {
                            html5
                          }
                          explanation {
                            html5
                          }
                          isFlashcard
                        }
                        ... on PersonContent {
                          id
                          name
                          birthYear
                          deceasedYear
                          about {
                            html5
                          }
                          image {
                            variation(identifier: fullwidth) {
                              uri
                            }
                            height
                            width
                            alternativeText
                          }
                          imageCaption
                        }
                        ... on WhatIsContent {
                          id
                          title
                          image {
                            uri
                            alternativeText
                            variation(identifier: fullwidth) {
                              uri
                            }
                          }
                          explanation {
                            html5
                          }
                        }
                        ... on SidenoteContent {
                          id
                          title
                          type
                          image {
                            uri
                            height
                            width
                            alternativeText
                          }
                          richtext {
                            html5
                          }
                          video {
                            id
                            title
                            image {
                              alternativeText
                              variation(identifier: fullwidth) {
                                uri
                              }
                            }
                            previewTitle
                            publishedBy
                            published
                            timestamps {
                              description
                              time
                            }
                            metadata
                            embedCode
                            transcription {
                              plaintext
                              html5
                            }
                            captions {
                              captionFile {
                                uri
                                fileName
                              }
                              language
                            }
                          }
                        }
                      }
                    }
                    locations {
                      children {
                        totalCount
                        edges {
                          node {
                            content {
                              ... on MathTaskContent {
                                id
                                assignmentText {
                                  plaintext
                                  text
                                  html5
                                }
                                _type {
                                  identifier
                                }
                                _info {
                                  relations {
                                    destinationContent {
                                      _url
                                      _type {
                                        identifier
                                      }
                                      ... on WhatIsContent {
                                        id
                                        title
                                        image {
                                          uri
                                          alternativeText
                                          variation(
                                            identifier: fullwidth
                                          ) {
                                            uri
                                          }
                                        }
                                        explanation {
                                          html5
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            id
                            priority
                          }
                        }
                      }
                    }
                    currentVersion {
                      creationDate {
                        timestamp
                      }
                      modificationDate {
                        timestamp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
